import { makeResetStyles, mergeClasses, shorthands } from '@griffel/react';
import { tokens, typographyStyles } from '@fluentui/react-theme';
export const messageBarBodyClassNames = {
    root: 'fui-MessageBarBody'
};
const useRootBaseStyles = makeResetStyles({
    ...typographyStyles.body1,
    ...shorthands.gridArea('body'),
    paddingRight: tokens.spacingHorizontalM
});
/**
 * Apply styling to the MessageBarBody slots based on the state
 */ export const useMessageBarBodyStyles_unstable = (state)=>{
    const rootBaseStyles = useRootBaseStyles();
    state.root.className = mergeClasses(messageBarBodyClassNames.root, rootBaseStyles, state.root.className);
    return state;
};
