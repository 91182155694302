import { makeStyles, mergeClasses } from '@griffel/react';
import { useButtonStyles_unstable } from '@fluentui/react-button';
import { tokens } from '@fluentui/react-theme';
export const carouselButtonClassNames = {
    root: 'fui-CarouselButton',
    icon: 'fui-CarouselButton__icon'
};
/**
 * Styles for the root slot
 */ const useStyles = makeStyles({
    root: {
        marginTop: 'auto',
        marginBottom: 'auto',
        color: tokens.colorNeutralForeground2,
        backgroundColor: tokens.colorNeutralBackgroundAlpha,
        pointerEvents: 'all',
        ':hover': {
            cursor: 'pointer'
        }
    }
});
/**
 * Apply styling to the CarouselButton slots based on the state
 */ export const useCarouselButtonStyles_unstable = (state)=>{
    'use no memo';
    const styles = useStyles();
    state = {
        ...state,
        ...useButtonStyles_unstable(state)
    };
    state.root.className = mergeClasses(carouselButtonClassNames.root, styles.root, state.root.className);
    if (state.icon) {
        state.icon.className = mergeClasses(carouselButtonClassNames.icon, state.icon.className);
    }
    return state;
};
